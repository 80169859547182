<template>
  <div id="app">
    <div class="header">
      <div class="title">
        <img src="@/assets/logo.jpg" />
        <div>
          <h1>XenixVPN</h1>
          <h2>Приватный VPN</h2>
        </div>
      </div>
      <a href="https://t.me/xenvpn_bot">
        <img src="@/assets/icons/telegram.svg" />
      </a>
    </div>

    <router-view class="view" />
  </div>
</template>

<script>
import 'animate.css';

export default {
  
}
</script>

<style>
a {
  text-decoration: none;
  color: unset;
}
.promotion {
  background: black;
  display: flex;
  gap: 50px;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
}
.promotion h1{
  font-weight: 400;
  font-size: 100px;
}

.view {
  margin-top: 32px;
  padding-bottom: 320px;
  padding-left: 32px;
  padding-right: 32px;
}
body {
  background: black;
  color: white;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}
.header h1 {
  font-size: 24px;
}

.header .title {
  display: flex;
  gap: 16px;
}
.header h2 {
  font-size: 16px;
  color: gray;
  font-weight: 300;
}

.header img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.header {
  background: black;
  gap: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
}
</style>
